import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const accessToken: any = localStorage.getItem("serviceToken");

  useEffect(() => {

    if(!accessToken) {
      navigate('../login')
    }

  }, []);


  return children;
};

export default AuthGuard;
