// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { BorderOutlined, QuestionOutlined, StopOutlined, GlobalOutlined, HeartOutlined, FilePdfOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  BorderOutlined,
  QuestionOutlined,
  StopOutlined,
  GlobalOutlined,
  HeartOutlined,
  FilePdfOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const candidate: NavItemType = {
  id: 'group-pages',
  title: <FormattedMessage id="Menu do Candidato" />,
  type: 'group',
  children: [
    {
      id: 'search-vacany',
      title: <FormattedMessage id="Painel de Vagas" />,
      type: 'item',
      icon: icons.GlobalOutlined,
      url: 'candidate/vacancies',
    },    
    {
      id: 'my-application',
      title: <FormattedMessage id="Minhas Candidaturas" />,
      type: 'item',
      url: 'candidate/applications',
      icon: icons.HeartOutlined
    },
    {
      id: 'cv',
      title: <FormattedMessage id="Meu Curriculo" />,
      type: 'item',
      url: 'candidate/cv',
      icon: icons.FilePdfOutlined
    },
  ]
};

export default candidate;
