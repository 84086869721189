// project import
import panel from './panel';
import widgets from './widgets';
import user from './user';

// types
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [panel]
};

export default menuItems;
