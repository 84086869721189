import { lazy } from 'react';

import Loadable from 'components/Loadable';
import AdminDashboardLayout from 'layout/AdminDashboard';

const AuthLogin = Loadable(lazy(() => import('pages/admin/auth/login')));
const Panel = Loadable(lazy(() => import('pages/admin/panel')));
const Vacancies = Loadable(lazy(() => import('pages/admin/vacancy/list')));
const Application = Loadable(lazy(() => import('pages/admin/application')));
const Candidates = Loadable(lazy(() => import('pages/admin/candidates')));
const Accounts = Loadable(lazy(() => import('pages/admin/accounts')));

const AdminRoutes = {
  path: '/',
  children: [
    {
      path: 'admin/login',
      element: <AuthLogin />
    },
   {
      path: '/',
      element: <AdminDashboardLayout />,
      children: [
        {
          path: 'admin/panel/',
          element: <Panel />
        }
      ]
    },{
      path: '/',
      element: <AdminDashboardLayout />,
      children: [
        {
          path: 'admin/vacancies/',
          element: <Vacancies />
        }
      ]
    },
    {
      path: '/',
      element: <AdminDashboardLayout />,
      children: [
        {
          path: 'admin/applications/',
          element: <Application />
        }
      ]
    },
    {
      path: '/',
      element: <AdminDashboardLayout />,
      children: [
        {
          path: 'admin/accounts/',
          element: <Accounts />
        }
      ]
    },
    {
      path: '/',
      element: <AdminDashboardLayout />,
      children: [
        {
          path: 'admin/candidates/',
          element: <Candidates />
        }
      ]
    },
  ]
};

export default AdminRoutes;
