import React, { createContext, useEffect, useReducer } from 'react';
import { Chance } from 'chance';
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions';
import authReducer from 'contexts/auth-reducer/auth';
import Loader from 'components/Loader';
import axios from 'utils/axios';
import { AuthProps, JWTContextType } from 'types/auth';

const chance = new Chance();

const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const setSession = (serviceToken?: string | null, client?: any, uid?: any, subdomain?: any) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    localStorage.setItem('client', client);
    localStorage.setItem('uid', uid);
    localStorage.setItem('subdomain', subdomain);

    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    axios.defaults.headers.common.Client = client;
    axios.defaults.headers.common.uid = uid;

  } else {
    localStorage.removeItem('serviceToken');
    localStorage.removeItem('client');
    localStorage.removeItem('uid');
    localStorage.removeItem('subdomain');

    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = window.localStorage.getItem('serviceToken');
        const client = window.localStorage.getItem('client');
        const uid = window.localStorage.getItem('uid');
        const subdomain = window.localStorage.getItem('subdomain');


        if (serviceToken && client && uid && subdomain) {
          setSession(serviceToken, client, uid, subdomain);
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
            }
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
  }, []);

  const login = async (email: string, password: string) => {

    const API_URL = 'https://contratai.app/company_auth/sign_in'
    const response = await axios.post(API_URL, { email, password });

    const serviceToken = response.headers['authorization']
    const client = response.headers['client']
    const uid = response.headers['uid']
    const subdomain = response.data['account']['subdomain']
    const user = subdomain

    setSession(serviceToken, client, uid, subdomain);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers!),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = async (email: string) => { };

  const updateProfile = () => { };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
