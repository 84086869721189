// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  CalendarOutlined,
  DashboardOutlined,
  RocketOutlined,
} from "@ant-design/icons";

// type
import { NavItemType } from "types/menu";

// icons
const icons = { DashboardOutlined, CalendarOutlined, RocketOutlined };

// ==============================|| MENU ITEMS - PAGES ||============================== //

const pages: NavItemType = {
  id: "group-pages",
  title: <FormattedMessage id="Painel da Empresa" />,
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="Visão Geral" />,
      type: "item",
      url: "/company/panel/",
      icon: icons.DashboardOutlined,
    },
    {
      id: "my-vacancies",
      title: <FormattedMessage id="Minhas Vagas" />,
      type: "item",
      url: "/company/vacancies/list",
      icon: icons.CalendarOutlined,
    },
    {
      id: "add-new-vacancy",
      title: <FormattedMessage id="Adicionar nova Vaga" />,
      type: "item",
      url: "/company/vacancies/add",
      icon: icons.RocketOutlined,
    },
  ],
};

export default pages;
