// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { BorderOutlined, CheckCircleOutlined, GlobalOutlined, HeartOutlined, QuestionOutlined, StopOutlined, TeamOutlined, UsergroupAddOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';
import UserProfileBackRight from 'assets/images/profile/UserProfileBackRight';

// icons
const icons = {
  BorderOutlined,
  QuestionOutlined,
  CheckCircleOutlined,
  HeartOutlined,
  GlobalOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const admin: NavItemType = {
  id: 'other',
  title: <FormattedMessage id="Menu do Adminsitrador" />,
  type: 'group',
  children: [
    {
      id: 'search-vacany',
      title: <FormattedMessage id="Empresas" />,
      type: 'item',
      url: 'admin/accounts',
      icon: icons.GlobalOutlined
    },    
    {
      id: 'vacancies',
      title: <FormattedMessage id="Vagas" />,
      type: 'item',
      url: 'admin/vacancies',
      icon: icons.HeartOutlined
    },
    {
      id: 'vacancies',
      title: <FormattedMessage id="Candidatos" />,
      type: 'item',
      url: 'admin/candidates',
      icon: icons.TeamOutlined
    },
      ]
};

export default admin;
