import { createBrowserRouter } from 'react-router-dom';

// project import
import CompanyRoutes from './CompanyRoutes';
import CandidateRoutes from './CandidateRoutes';
import LoginRoutes from './LoginRoutes';
import PortalRoutes from './PortalRoutes';
import AdminRoutes from './AdminRoutes';


const router = createBrowserRouter([LoginRoutes, AdminRoutes,CompanyRoutes, CandidateRoutes, PortalRoutes], { basename: import.meta.env.VITE_APP_BASE_NAME });

export default router;
