import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import DashboardLayout from 'layout/Dashboard';
import PagesLayout from 'layout/Pages';
import SimpleLayout from 'layout/Simple';

// types
import { SimpleLayoutType } from 'types/config';

const Vacancy = Loadable(lazy(() => import('pages/portal/vacancy/list')));

// ==============================|| MAIN ROUTING ||============================== //
const PortalRoutes = {
  path: '/',
  children: [

    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: 'vacancy',
          element: <Vacancy />
        }
      ]
    },
  ]
};

export default PortalRoutes;
