import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuardCandidate = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const accessToken: any = localStorage.getItem("st");



  useEffect(() => {

    if(!accessToken) {
      navigate('../candidate/login')
    }


  }, []);

  return children;
};

export default AuthGuardCandidate;
