import axios, { AxiosRequestConfig } from "axios";

const axiosServices = axios.create({
  baseURL: "https://contratai.app/",
});

axiosServices.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem("serviceToken");
    const client = localStorage.getItem("client");
    const uid = localStorage.getItem("uid");
    const subdomain = localStorage.getItem('subdomain') || sessionStorage.getItem('subdomain');
    
    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["client"] = client;
      config.headers["uid"] = uid;
    }

    // Define baseURL com o subdomain atual antes de cada solicitação
    config.baseURL = `https://${subdomain}.contratai.app/`;
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => {
    const newToken = response.headers["authorization"];
    const newClient = response.headers["client"];
    const newUid = response.headers["uid"];

    localStorage.setItem("serviceToken", newToken);
    localStorage.setItem("client", newClient);
    localStorage.setItem("uid", newUid);

    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      window.location.pathname = "../login"
       localStorage.removeItem("serviceToken");
       localStorage.removeItem("client");
       localStorage.removeItem("uid");
    };
    
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosServices.get(url, { ...config });
  return res.data;
};

export const fetcherPost = async (
  args: string | [string, AxiosRequestConfig]
) => {
  const [url, config] = Array.isArray(args) ? args : [args];
  const res = await axiosServices.post(url, { ...config });
  return res.data;
};

export default axiosServices;
