import { lazy } from 'react';

import Loadable from 'components/Loadable';
import CandidateDashboardLayout from 'layout/CandidateDashboard';
import ForgotPassword from 'pages/candidate/auth/forgot-password';

const AuthLogin = Loadable(lazy(() => import('pages/candidate/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/candidate/auth/register')));
const Panel = Loadable(lazy(() => import('pages/candidate/panel')));
const Vacancies = Loadable(lazy(() => import('pages/candidate/vacancy/list')));
const Application = Loadable(lazy(() => import('pages/candidate/application')));
const Cv = Loadable(lazy(() => import('pages/candidate/cv')));


const CandidateRoutes = {
  path: '/',
  children: [
    {
      path: 'candidate/login',
      element: <AuthLogin />
    },
    {
      path: 'candidate/register',
      element: <AuthRegister />
    }, {
      path: '/',
      element: <CandidateDashboardLayout />,
      children: [
        {
          path: 'candidate/panel/',
          element: <Vacancies />
        }
      ]
    },{
      path: '/',
      element: <CandidateDashboardLayout />,
      children: [
        {
          path: 'candidate/vacancies/',
          element: <Vacancies />
        }
      ]
    },
    {
      path: '/',
      element: <CandidateDashboardLayout />,
      children: [
        {
          path: 'candidate/applications/',
          element: <Application />
        }
      ]
    },
    {
      path: '/',
      element: <CandidateDashboardLayout />,
      children: [
        {
          path: 'candidate/cv/',
          element: <Cv />
        }
      ]
    },
    {
      path: '/',
      children: [
        {
          path: 'candidate/forgot-password/',
          element: <ForgotPassword />
        }
      ]
    },
  ]
};

export default CandidateRoutes;
