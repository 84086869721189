// material-ui
import { Container, Grid, Typography } from '@mui/material';

// project imports
import ContactHeader from 'sections/extra-pages/Header';

function Home() {
  return (
    <Grid container spacing={12} justifyContent="center" alignItems="center" sx={{ mb: 12 }}>
      <Grid item xs={12} md={12}>
        <ContactHeader />
      </Grid>
      <Grid item xs={12} sm={10} lg={9}>
        <Container maxWidth="lg" sx={{ px: { xs: 0, sm: 2 } }}>
          <h1>A Solução que você procura</h1>
        <Typography>
        Nascida em Sorocaba, a ContratAI é uma startup que tem como missão facilitar o processo de atração de profissionais para outras empresas, contribuindo ativamente para a redução do desemprego no país. Utilizando tecnologia de Inteligência Artificial (IA), oferecemos uma plataforma inovadora que agiliza e otimiza todo o processo de atração e seleção.
        </Typography>
        </Container>
      </Grid>
    </Grid>
  );
}

export default Home;
