import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuardAdmin = ({ children }: GuardProps) => {
  const navigate = useNavigate();
  const accessToken: any = localStorage.getItem("st_ad");

  useEffect(() => {

    if(!accessToken) {
      navigate('../admin/login')
    }

  }, []);

  return children;
};

export default AuthGuardAdmin;
